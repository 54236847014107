<template>
  <div class="py-4 container-fluid">
    <div v-if="showVerificationModal" class="modal show d-block" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Код верифікації</h5>
            <button type="button" class="btn-close" @click="closeVerificationModal"></button>
          </div>
          <div class="modal-body text-center">
            <p>Ваш код:</p>
            <h2 class="text-info mb-4">{{ verificationCode }}</h2>
            <p class="text-muted">{{ verificationMessage }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info" @click="closeVerificationModal">OK</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showVerificationModal" class="modal-backdrop show"></div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Додати новий пристрій</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="/items" class="mb-0 btn bg-gradient-success btn-sm"
                    >&nbsp; Назад</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <soft-button
              v-if="file"
              size="sm"
              style="margin-right: 10px"
              color="info"
              @click="$refs.pfp.click()"
              >Змінити</soft-button
            >
            <soft-button v-if="file" size="sm" color="danger" @click="file = null"
              >Видалити</soft-button
            >
            <validation-error :errors="apiValidationErrors.image" />

            <label class="form-label mt-2 row mt-4">Назва пристрою</label>
            <soft-model-input
              id="name"
              v-model="item.name"
              type="text"
              placeholder="Назва пристрою"
            />
            <validation-error :errors="apiValidationErrors.name" />

            <label class="form-label mt-2 row mt-4">ФІО власника</label>
            <soft-model-input
              id="owner"
              v-model="item.owner"
              type="text"
              placeholder="Введіть власника пристрою"
            />
            <validation-error :errors="apiValidationErrors.serial_number" />

            <label class="form-label mt-2 row mt-4">Серійний номер</label>
            <soft-model-input
              id="serial_number"
              v-model="item.serial_number"
              type="number"
              placeholder="Введіть серійний номер пристрою"
            />
            <validation-error :errors="apiValidationErrors.serial_number" />

            <div v-if="showVerificationInput" class="mt-4">
              <label class="form-label">Verification Code</label>
              <soft-model-input
                id="verification_token"
                v-model="verificationToken"
                type="text"
                maxlength="4"
                placeholder="Enter 4-digit code"
              />
            </div>

            <label class="form-label mt-2 row mt-4">Адреса</label>
            <soft-model-input
              id="description"
              v-model="item.description"
              placeholder="Адреса пристрою"
            />
            <validation-error :errors="apiValidationErrors.excerpt" />

            <label class="form-label mt-2 row mt-4">Тип пристрою</label>
            <select
              id="choices-category"
              v-model="getCategory"
              class="form-control"
              name="choices-category"
            ></select>
            <validation-error :errors="apiValidationErrors.category" />
            

            <soft-button
              color="dark"
              variant="gradient"
              class="float-end mt-4 mb-0"
              size="sm"
              :is-disabled="loading ? true : false"
              @click="newItem"
              ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Додати</span></soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import "@vuepic/vue-datepicker/dist/main.css";
import Choices from "choices.js";

export default {
  name: "NewItem",
  components: {
    SoftModelInput,
    SoftButton,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      file: null,
      item: {
        status: "on",
        is_on_homepage: true,
        serial_number: null,
        name: 'Пристрій 1',
        description: '',
        excerpt: ''
      },
      loading: false,
      getDate: new Date(),
      getCategory: null, 
      verificationStatus: null,
      verificationCode: null,
      verificationToken: '',
      showVerificationInput: false,
      showVerificationModal: false,
      verificationMessage: '',
    };
  },

  computed: {
    categories() {
      var categs = this.$store.getters["categories/categories"]?.data;
      categs = categs.sort((a,b)=> a.id - b.id);
      var response = [];
      if (categs) {
        categs.forEach((element, index) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
            selected: index == categs.length-1 ? true : false
          });
        });
      }
      return response;
    },
    preview() {
      if (this.file) return URL.createObjectURL(this.file);
      else return require("/src/assets/img/placeholder.jpg");
    },
  },

  async mounted() {
    await this.$store.dispatch("categories/getCategories");
    const categories = this.$store.getters["categories/categories"]?.data;
    console.log('Available categories:', categories);
    
    if (categories && categories.length > 0) {
      const lastCategory = categories.sort((a,b)=> a.id - b.id).slice(-1)[0];
      console.log('Selected last category:', lastCategory);
      this.getCategory = lastCategory.name;
    }

    if (document.getElementById("choices-category")) {
      var element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
        choices: this.categories,
      });
    }
  },

  methods: {
    format(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async newItem() {
      this.resetApiValidation();
      this.loading = true;

      try {
        if (!this.item.serial_number || this.item.serial_number <= 0) {
          showSwal.methods.showSwal({
            type: "error",
            message: "Будь ласка, введіть коректний Serial Number",
            width: 350,
          });
          this.loading = false;
          return;
        }

        await this.createItem();
      } catch (error) {
        console.error('Error details:', error);
        if (error.response?.data?.errors) {
          console.error('API validation errors:', error.response.data.errors);
          this.setApiValidation(error.response.data.errors);
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: error.message || "Oops, something went wrong!",
            width: 350,
          });
        }
        this.loading = false;
      }
    },

    async createItem() {
      try {
        const id = this.categories.filter((obj) => {
          return obj.value === this.getCategory;
        })[0]?.id;

        if (!id) {
          throw new Error('Будь ласка, виберіть тип пристрою');
        }

        // Prepare the data object for the API request
        const requestData = {
          data: {
            type: "items",
            attributes: {
              serial_number: this.item.serial_number,
              description: this.item.description,
              excerpt: this.item.description,
              name: this.item.name,
              owner: this.item.owner,
              status: 'pending',
              is_on_homepage: false
            },
            relationships: {
              category: {
                data: {
                  type: "categories",
                  id: id
                }
              }
            }
          }
        };
        
        const response = await this.$store.dispatch("items/addItem", requestData);
        
        // Получаем токен из метаданных ответа
        const verificationToken = response.data.meta.verification_token;
        
        // Показываем модальное окно с токеном
        this.verificationCode = verificationToken;
        this.verificationMessage = "Будь ласка, збережіть цей код. Він потрібен для верифікації пристрою.";
        this.showVerificationModal = true;
        
      } catch (error) {
        console.error('Error details:', error);
        if (error.response?.data?.errors) {
          console.error('API validation errors:', error.response.data.errors);
          this.setApiValidation(error.response.data.errors);
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: error.message || "Помилка при створенні пристрою",
            width: 350,
          });
        }
        this.loading = false;
      }
    },
    addedImage(files) {
      this.file = files[0];
    },
    closeVerificationModal() {
      this.showVerificationModal = false;
      this.$router.push("/items");
    },
    showVerificationTokenModal(token) {
      showSwal.methods.showSwal({
        type: "info",
        title: "Код верифікації",
        message: `Ваш код верифікації: ${token}`,
        confirmButtonText: "OK",
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCloseButton: false,
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}

.choices{
  margin-bottom: 0px !important;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}

.text-primary {
  color: #5e72e4 !important;
  font-size: 2rem;
  font-weight: bold;
}
</style>
