<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити данні пристрою</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="/items" class="mb-0 btn bg-gradient-info btn-sm"
                  >&nbsp; Назад
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <label class="form-label mt-2 row mt-4">Назва пристрою</label>
                <soft-model-input
                    id="name"
                    v-model="item.name"
                    type="text"
                    placeholder="Пристрій №1 (дім)"
                />
                <validation-error :errors="apiValidationErrors.name"/>
              </div>
              <div class="col-6">
                <label class="form-label mt-2 row mt-4">Власник</label>
                <soft-model-input
                    id="owner"
                    v-model="item.owner"
                    type="text"
                    placeholder="Власник(ФІО)"
                />
                <validation-error :errors="apiValidationErrors.owner"/>

              </div>
            </div>

            <label class="form-label mt-2 row mt-4">Адреса</label>
            <soft-model-input
                id="description"
                v-model="item.description"
                placeholder="Description"
            />
            <validation-error :errors="apiValidationErrors.description"/>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <label class="form-label mt-2 row mt-4">Серійний номер</label>
                <input
                    id="serial_number"
                    v-model="item.serial_number"
                    type="text"
                    class="form-control"
                    readonly
                    placeholder="Введіть серійний номер"
                />
                <validation-error :errors="apiValidationErrors.serial_number"/>
              </div>
              <div class="col-md-6 col-sm-12">
                <label class="form-label mt-2 row mt-4">Тип пристрою</label>
                <select
                    id="choices-category"
                    v-model="getCategory"
                    class="form-control"
                    name="choices-category"
                ></select>
                <validation-error :errors="apiValidationErrors.category"/>

              </div>
            </div>

 
            <label class="form-label mt-2 row mt-4">Синхронізація з контролером </label>
            <soft-model-switch
                id="homepage"
                v-model="item.is_on_homepage"
                name="homepage"
            />
            <span :class="{
              'text-success': item.status === 'on',
              'text-warning': item.status === 'off',
              'text-danger': item.status === 'connecting',
              'text-info': item.status === 'pending'
            }">
              {{ item.status === 'on' ? 'з\'єднання встановлено' : 
                 item.status === 'off' ? 'синхронізація виключена,з\'єднання встановлено' : 
                 item.status === 'connecting' ? 'з\'єднання немає' :
                 item.status === 'pending' ? `очікуємо підтвердження (код: ${item.verification_token})` : '' }}
            </span>

            <div class="d-none">
              <label class="form-label mt-2 row mt-4 ">Статус</label>
              <div class="form-check">
                <input
                    id="published"
                    v-model="item.status"
                    value="published"
                    type="radio"
                    class="form-check-input"
                />
                <label for="published">Підкючений</label>
              </div>

              <div class="form-check">
                <input
                    id="archive"
                    v-model="item.status"
                    value="archive"
                    type="radio"
                    class="form-check-input"
                />
                <label for="archive">Немає з'єднання</label>
              </div>
            </div>


            <validation-error :errors="apiValidationErrors.status"/>


            <label class="form-label mt-2 row mt-4">Дата додавання пристрою</label>
            <Datepicker
                v-model="getDateCreate"
                disabled
                :enable-time-picker="false"
                :format="format"
                auto-apply
                placeholder="Дата додавання пристрою"
            ></Datepicker>
            <validation-error :errors="apiValidationErrors.created_at"/>

            <label class="form-label mt-2 row mt-4">Останнє оновлення</label>
            <Datepicker
                v-model="getDate"
                disabled
                :enable-time-picker="false"
                :format="format"
                auto-apply
                placeholder="Дата змін в пристрою"
            ></Datepicker>
            <validation-error :errors="apiValidationErrors.updated_at"/>


            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="editItem"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити данні</span></soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import SoftModelSwitch from "/src/components/SoftModelSwitch.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Choices from "choices.js";

export default {
  name: "EditItem",
  components: {
    SoftModelInput,
    SoftButton,
    ValidationError,
    SoftModelSwitch,
    Datepicker,
  },
  mixins: [formMixin],
  data() {
    return {
      item: {},
      loading: false,
      getDate: null,
      getDateCreate: null,
      getCategory: null,
      getTags: [],
    };
  },

  computed: {
    categories() {
      const categs = this.$store.getters["categories/categories"]?.data;

      var response = [];
      if (categs) {
        categs.forEach((element) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
          });
        });
      }
      return response;
    },
    // Отримуємо ID користувача зі стану
    currentUser() {
      return this.$store.getters["profile/profile"]?.id;
    },
    tags() {
      const tags = this.$store.getters["tags/tags"]?.data;
      var response = [];
      if (tags) {
        tags.forEach((element) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
          });
        });
      }
      return response;
    },
  },

  watch: {
    'item.category': {
      handler(newVal) {
        if (newVal && newVal.name && document.getElementById("choices-category")) {
          const element = document.getElementById("choices-category");
          const choices = element.choices;
          if (choices) {
            choices.setChoiceByValue(newVal.name);
          }
        }
      },
      immediate: true
    }
  },

  async mounted() {
    try {
      // Спочатку завантажуємо категорії
      await this.$store.dispatch("categories/getCategories");
      // Потім завантажуємо дані пристрою
      await this.loadItem();
      // Ініціалізуємо Choices після завантаження даних
      this.initializeChoices();
    } catch (error) {
      console.error('Error in mounted:', error);
      showSwal({
        title: 'Помилка',
        text: 'Не вдалося завантажити дані',
        icon: 'error'
      });
    }
  },

  methods: {
    format(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async loadItem() {
      try {
        this.loading = true;
        const itemId = this.$route.params.id;
        if (!itemId) {
          console.error('No item ID provided');
          return;
        }

        const response = await this.$store.dispatch("items/getItem", itemId);
        if (response && response.data) {
          console.log('Loaded item data:', response.data);
          this.item = response.data;
          this.getDate = new Date(this.item.updated_at);
          this.getDateCreate = new Date(this.item.created_at);
          
          // Встановлюємо категорію
          if (this.item.category && this.item.category.name) {
            console.log('Setting category:', this.item.category.name);
            this.getCategory = this.item.category.name;
          }
        }
      } catch (error) {
        console.error('Error loading item:', error);
        showSwal({
          title: 'Помилка',
          text: 'Не вдалося завантажити дані пристрою',
          icon: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    initializeChoices() {
      if (document.getElementById("choices-category")) {
        const element = document.getElementById("choices-category");
        console.log('Initializing choices with categories:', this.categories);
        const choices = new Choices(element, {
          searchEnabled: false,
          choices: [
            {
              value: "",
              label: "Оберіть тип пристрою",
              selected: true,
              disabled: true,
            },
          ].concat(this.categories),
        });

        // Встановлюємо значення категорії після ініціалізації
        if (this.getCategory) {
          console.log('Setting choice value:', this.getCategory);
          choices.setChoiceByValue(this.getCategory);
        }
      }
    },

    async editItem() {
      this.resetApiValidation();
      this.loading = true;

      if (!this.item.id) {
        console.error("ID елемента відсутнє. Оновлення неможливе.");
        showSwal.methods.showSwal({
          type: "error",
          message: "ID елемента відсутнє. Оновлення неможливе.",
        });
        this.loading = false;
        return;
      }

      try {
        // Формуємо дані для оновлення
        const updateData = {
          id: this.item.id,
          name: this.item.name,
          owner: this.item.owner,
          description: this.item.description,
          is_on_homepage: this.item.is_on_homepage,
          status: this.item.status,
          updated_at: this.format(this.getDate || new Date())
        };

        // Додаємо категорію, якщо вона вибрана
        const categoryId = this.categories.find((obj) => obj.value === this.getCategory)?.id;
        if (categoryId) {
          console.log('Adding category_id:', categoryId);
          updateData.category_id = categoryId;
        }

        console.log('Sending update data:', updateData);

        // Відправляємо оновлення
        await this.$store.dispatch("items/editItem", updateData);

        showSwal.methods.showSwal({
          type: "success",
          message: "Дані оновлено!",
        });
        this.$router.push("/items");
      } catch (error) {
        console.error('Error updating item:', error);
        if (error.response?.data?.errors) {
          this.setApiValidation(error.response.data.errors);
        } else {
          showSwal.methods.showSwal({
            type: "error",
            message: "Упс, помилка 350!",
            width: 350,
          });
        }
      } finally {
        this.loading = false;
      }
    }

  },
};
</script>
<style>
img {
  object-fit: cover;
}

.choices {
  margin-bottom: 0px !important;
}
</style>
