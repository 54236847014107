<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Основна інформація</h5>
              </div>
            </div>
          </div>

          <div class="card-body">
            <label class="form-label mt-2 row">ФІО</label>
            <soft-model-input
                id="name"
                v-model="user.name"
                type="text"
                placeholder="Ваше ім'я"
            />
            <validation-error :errors="apiValidationErrors.name"/>

            <label class="form-label mt-4">Телефон для зв'язку (не використовується для розсилки)</label>
            <soft-input
                id="phoneNumber"
                type="phone"
                placeholder="+38 099 111 22 33"
            />

            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="updateProfile"
            >
              <span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити дані</span>
            </soft-button>
          </div>
        </div>
        <div class="card mt-4">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити пароль</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label class="form-label mt-2 row">Поточний пароль</label>
            <soft-model-input
                id="current_password"
                v-model="passwordForm.current_password"
                type="password"
                placeholder="Поточний пароль"
            />
            <validation-error :errors="apiValidationErrors.current_password"/>

            <label class="form-label row mt-4">Новий пароль</label>
            <soft-model-input
                id="new_password"
                v-model="passwordForm.new_password"
                type="password"
                placeholder="Новий пароль"
            />
            <validation-error :errors="apiValidationErrors.new_password"/>

            <label class="form-label row mt-4">Підтвердження нового паролю</label>
            <soft-model-input
                id="password_confirmation"
                v-model="passwordForm.password_confirmation"
                type="password"
                placeholder="Підтвердження нового паролю"
            />
            <validation-error :errors="apiValidationErrors.password_confirmation"/>

            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="updatePassword"
            >
              <span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити пароль</span>
            </soft-button>
          </div>
        </div>

      </div>


      <div class="col-6">
     
        <div id="notifications" class="card">
          <div class="card-header">
            <h5>Сповіщення</h5>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                <tr>
                  <th class="ps-1" colspan="4">
                    <p class="mb-0">Подія</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">Email</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">SMS</p>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <span class="text-dark d-block text-sm">Подія1</span>
                      <span class="text-xs font-weight-normal">text1</span>
                    </div>
                  </td>
                  <td>
                    <div class="ms-auto">
                      <soft-switch
                          id="mentionsEmail"
                          name="mentionsEmail"
                          checked
                      />
                    </div>
                  </td>
                  <td>
                    В розробці
                  </td>
                </tr>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <p class="text-sm mb-0">Log in from a new device</p>
                    </div>
                  </td>
                  <td>
                    <div class="ms-auto">
                      <soft-switch id="deviceEmail" name="deviceEmail" disabled/>
                    </div>
                  </td>
                  <td>
                    В розробці
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити email</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label class="form-label row mt-4">Поточний Email</label>
            <input
                id="email"
                type="text"
                class="form-control"
                name="email"
                :value="user.email"
                disabled
            />

            <label class="form-label row mt-4">Новий email</label>
            <soft-model-input
                id="new_email"
                v-model="emailForm.email"
                type="email"
                placeholder="Новий email"
            />
            <validation-error :errors="apiValidationErrors.email"/>
            
            <label class="form-label mt-2 row">Поточний пароль</label>
            <soft-model-input
                id="current_password_email"
                v-model="emailForm.current_password"
                type="password"
                placeholder="Поточний пароль"
            />
            <validation-error :errors="apiValidationErrors.current_password"/>


            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="updateEmail"
            >
              <span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити email</span>
            </soft-button>
          </div>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5>Видалення профілю</h5>
            <p class="text-sm mb-0">
              Після видалення облікового запису поновити не можливо.
            </p>
          </div>
          <div class="card-body d-sm-flex pt-0 justify-content-between">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div>
                <soft-switch
                    v-model="deleteAccount"
                    id="deleteAccount"
                    name="deleteAccount"
                />
              </div>
              <div class="ms-2">
                <span class="text-dark font-weight-bold d-block text-sm">Підтвержую</span>
                <span class="text-xs d-block">Я хочу видалити свій акаунт.</span>
              </div>
            </div>

            <soft-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                type="button"
                name="button"
                :is-disabled="!deleteAccount"
                @click="deleteProfile"
            >Видалити</soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from "@/services/profile.service.js";
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue"; 
import ValidationError from "@/components/ValidationError.vue"; 
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";

export default {
  name: "AccountSettings",
  components: {
    SoftSwitch,
    SoftInput,
    SoftModelInput,
    SoftButton,
    ValidationError,
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        type: ""
      },
      passwordForm: {
        current_password: "",
        new_password: "",
        password_confirmation: ""
      },
      emailForm: {
        current_password: "",
        email: ""
      },
      loading: false,
      deleteAccount: false,
      apiValidationErrors: {}
    };
  },
  async created() {
    await this.loadProfile();
  },
  methods: {
    async loadProfile() {
      try {
        const response = await ProfileService.getProfile();
        this.user = {
          name: response.name,
          email: response.email,
          type: response.type
        };
      } catch (error) {
        console.error("Error loading profile:", error);
      }
    },
    async updateProfile() {
      this.loading = true;
      try {
        await ProfileService.updateProfile({
          name: this.user.name
        });
        this.$store.dispatch("profile/getProfile");
        this.$swal({
          title: "Успіх!",
          text: "Профіль успішно оновлено",
          icon: "success",
          confirmButtonText: "OK"
        });
      } catch (error) {
        console.error("Error updating profile:", error);
        this.$swal({
          title: "Помилка!",
          text: "Не вдалося оновити профіль",
          icon: "error",
          confirmButtonText: "OK"
        });
      } finally {
        this.loading = false;
      }
    },
    async updatePassword() {
      this.loading = true;
      try {
        await ProfileService.updatePassword(this.passwordForm);
        this.$swal({
          title: "Успіх!",
          text: "Пароль успішно оновлено",
          icon: "success",
          confirmButtonText: "OK"
        });
        this.passwordForm = {
          current_password: "",
          new_password: "",
          password_confirmation: ""
        };
      } catch (error) {
        console.error("Error updating password:", error);
        this.$swal({
          title: "Помилка!",
          text: error.message || "Не вдалося оновити пароль",
          icon: "error",
          confirmButtonText: "OK"
        });
      } finally {
        this.loading = false;
      }
    },
    async updateEmail() {
      this.loading = true;
      try {
        await ProfileService.updateEmail(this.emailForm);
        this.$swal({
          title: "Успіх!",
          text: "Email успішно оновлено",
          icon: "success",
          confirmButtonText: "OK"
        });
        this.emailForm = {
          current_password: "",
          email: ""
        };
        await this.loadProfile();
      } catch (error) {
        console.error("Error updating email:", error);
        this.$swal({
          title: "Помилка!",
          text: error.message || "Не вдалося оновити email",
          icon: "error",
          confirmButtonText: "OK"
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteProfile() {
      if (!this.deleteAccount) return;
      
      this.loading = true;
      try {
        await ProfileService.deleteProfile();
        this.$store.dispatch("auth/logout");
        this.$router.push("/authentication/signin/basic");
      } catch (error) {
        console.error("Error deleting profile:", error);
        this.$swal({
          title: "Помилка!",
          text: "Не вдалося видалити профіль",
          icon: "error",
          confirmButtonText: "OK"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script> 