import axios from "axios";
import authHeader from "./auth-header";
import Jsona from "jsona";
const dataFormatter = new Jsona();

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';

export default {
    async getUserByItemId(itemId) {
        const response = await axios.get(`${API_URL}items/${itemId}/user`, {
            headers: authHeader(),
        });
        return dataFormatter.deserialize(response.data);
    },

    async getItemsByUserId(userId) {
        console.log('Making request to:', API_URL + "items");
        console.log('With user_id:', userId);
        const response = await axios.get(API_URL + "items", {
            headers: {
                ...authHeader(),
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            params: {
                include: "category",
                "filter[user_id]": userId,
                _t: new Date().getTime()
            },
        });
        console.log('Server response:', response.data);
        return {
            data: dataFormatter.deserialize(response.data),
            meta: response.data.meta,
            included: response.data.included
        };
    },

    async getItems(params) {
        console.log('Making request to:', API_URL + "items");
        console.log('With params:', {
            include: "category",
            ...(params && params.filter && params.filter.name ? { "filter[name]": params.filter.name } : {}),
            ...(params && params.page && params.page.size ? { "page[size]": params.page.size } : {}),
            ...(params && params.page && params.page.number ? { "page[number]": params.page.number } : {}),
            ...(params && params.sort ? { sort: params.sort } : {}),
            ...(params && params.user_id ? { "filter[user_id]": params.user_id } : {}),
        });
        const response = await axios.get(API_URL + "items", {
            headers: {
                ...authHeader(),
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            params: {
                include: "category",
                _t: new Date().getTime(),
                ...(params && params.filter && params.filter.name ? { "filter[name]": params.filter.name } : {}),
                ...(params && params.page && params.page.size ? { "page[size]": params.page.size } : {}),
                ...(params && params.page && params.page.number ? { "page[number]": params.page.number } : {}),
                ...(params && params.sort ? { sort: params.sort } : {}),
                ...(params && params.user_id ? { "filter[user_id]": params.user_id } : {}),
            },
        });
        console.log('Server response:', response.data);
        return {
            data: dataFormatter.deserialize(response.data),
            meta: response.data.meta,
            included: response.data.included
        };
    },

    async getItem(id) {
        const response = await axios.get(API_URL + "items/" + id, {
            headers: {
                ...authHeader(),
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            params: {
                include: "category",
                _t: new Date().getTime()
            },
        });
        return {
            data: dataFormatter.deserialize(response.data),
            included: response.data.included
        };
    },

    async deleteItem(id) {
        try {
            // Спочатку перевіряємо чи існує пристрій
            await this.getItem(id);
            // Якщо пристрій існує, видаляємо його
            await axios.delete(API_URL + "items/" + id, {
                headers: authHeader()
            });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                throw new Error('Пристрій не знайдено');
            }
            throw error;
        }
    },

    async addItem(item) {
        return await axios.post(API_URL + "items", item, {
            headers: {
                ...authHeader(),
                'Content-Type': 'application/vnd.api+json'
            }
        });
    },

    async editItem(item) {
        console.log('Original item data:', item);

        // Create data object in JSON:API format
        const data = {
            data: {
                type: "items",
                id: item.id.toString(),
                attributes: {}
            }
        };

        // Copy all attributes except system fields
        Object.keys(item).forEach(key => {
            if (key !== 'id' &&
                key !== 'type' &&
                key !== 'created_at' &&
                key !== 'updated_at' &&
                key !== 'links' &&
                item[key] !== undefined &&
                item[key] !== null) {
                // Convert boolean values properly
                if (key === 'burner_mod') {
                    data.data.attributes[key] = Boolean(item[key]);
                } else {
                    data.data.attributes[key] = item[key];
                }
            }
        });

        console.log('Final request data:', data);

        return await axios.patch(
            API_URL + "items/" + item.id,
            data, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'application/vnd.api+json'
                }
            }
        );
    },

    async uploadItemPic(object) {
        let formData = new FormData();
        formData.append("attachment", object.pic);
        return (
            await axios.post(
                API_URL + "uploads/items/" + object.id + "/image",
                formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            )
        ).data.url;
    },
};