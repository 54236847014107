<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <h5 class="mb-0">Підключені пристрої</h5>
              <router-link to="/items/new" class="mb-0 btn bg-gradient-info btn-sm ms-auto mt-4 mt-lg-0"> + додати
              </router-link>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div v-if="itemsList?.length > 0" class="device-list">
              <div v-for="item in itemsList" :key="item.id"
                   class="device-item d-grid mb-3 p-3 border rounded">
                <div class="device-info">
                  <h6 class="device-name mb-1">{{ item.name }}</h6>
                  <p class="device-owner text-muted">Власник: {{ item.owner }}</p>
                  <p class="device-category text-muted">Тип: {{ item.category?.name || 'Не вказано' }}</p>
                </div>
                <div class="device-status text-muted">
                  <p>Статус: <span :class="{
                    'text-warning': item.status === 'pending',
                    'text-success': item.status === 'on'
                  }">{{ item.status }}</span> 
                    <span v-if="item.status === 'pending'" class="text-warning">(Код: {{ item.verification_token }})</span>
                  </p>
                  <p>Синхронізація: {{ item.sync_status }}</p>
                </div>
                <div class="device-created text-muted">
                  <p>Доданий: {{ new Date(item.created_at).toLocaleDateString('uk-UA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }) }}</p>
                </div>
                <div class="device-actions mt-2 mt-md-0">
                  <button class="btn btn-edit-device bg-gradient-info text-center" @click="handleEdit(item.id)">Редагувати</button>
                  <button class="btn btn-delete-device bg-danger text-center" @click="handleDelete(item.id)">Видалити</button>
                </div>
              </div>
            </div>
            <div v-else class="text-center my-5">
              <p>Пристроїв не знайдено</p>
              <router-link to="/items/new" class="btn btn-add-device-big bg-gradient-info btn-sm text-center">
                <i class="fa fa-solid fa-plus"></i> Додати пристрій
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";

export default {
  name: "Items",
  data() {
    return {
      pagination: {
        currentPage: 1,
        perPage: 15,
        total: 0,
      },
      currentQuery: "",
      currentSort: "created_at",
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["profile/profile"]?.id;
    },
    itemsList() {
      return this.$store.getters["items/itemsByUser"]?.data || [];
    },
    metaPage() {
      return this.$store.getters["items/itemsByUser"]?.meta;
    },
  },
  watch: {
    metaPage: "updatePagination",
    '$route': {
      immediate: true,
      handler() {
        if (this.currentUser) {
          this.loadData();
        }
      }
    },
    currentUser: {
      immediate: true,
      handler(newUserId) {
        if (newUserId) {
          this.loadData();
        }
      }
    }
  },
  mounted() {
    if (this.currentUser) {
      this.loadData();
    }
  },
  methods: {
    handleDelete(itemId) {
      // Підтвердження видалення через Swal
      showSwal.methods.showSwalConfirmationDelete().then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Виконання видалення через Vuex
            await this.deleteItem(itemId);
          } catch (error) {
            console.error('Error deleting item:', error);
            showSwal.methods.showSwal({
              type: "error",
              message: "Помилка при видаленні пристрою",
            });
          }
        }
      });
    },

    async loadData() {
      try {
        console.log('Loading data with user_id:', this.currentUser);
        // Очищаємо store перед завантаженням нових даних
        this.$store.commit('items/CLEAR_ITEMS');
        // Завантажуємо нові дані
        await this.$store.dispatch("items/getItemsByUserId", this.currentUser);
        console.log('Store items after loading:', this.$store.getters["items/itemsByUser"]);
        console.log('ItemsList computed:', this.itemsList);
        if (this.itemsList.length > 0) {
          console.log('First device data:', this.itemsList[0]);
        }
      } catch (error) {
        console.error("Error loading items:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Помилка при завантаженні даних",
        });
      }
    },

    updatePagination() {
      const meta = this.metaPage;
      this.pagination.total = meta?.total || 0;
    },

    handleEdit(id) {
      this.$router.push(`/items/edit/${id}`);
    },

    async deleteItem(id) {
      try {
        await this.$store.dispatch("items/deleteItem", id);
        // Очищаємо store після видалення
        this.$store.commit('items/CLEAR_ITEMS');
        // Завантажуємо нові дані
        await this.loadData();
        showSwal.methods.showSwal({
          type: "success",
          message: "Пристрій успішно видалено!",
        });
      } catch (error) {
        console.error('Error deleting item:', error);
        showSwal.methods.showSwal({
          type: "error",
          message: error.message || "Помилка при видаленні пристрою",
        });
      }
    },
  },
};
</script>

<style scoped>
.device-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 1.5em;
}

.device-item {
  background: #fbfbfb;
}

.device-actions {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}
.device-actions button {
  flex: 1;
  color: #ffffff;
  gap: 10px;
}
.btn-add-device-big {
  padding: 2rem;
}
</style>
