<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <button type="button"
                class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
                data-bs-toggle="tooltip" data-bs-placement="bottom" title=""
                data-bs-html="true"
                :data-bs-original-title="infoBox" aria-label="info"><i
            class="fas fa-info" aria-hidden="true"></i></button>
      </div>
    </div>
    <div class="text-center card-body">
      <component :is="resolvedIcon" v-if="resolvedIcon"></component>

      <h1 class="text-gradient" :class="`text-${color}`">
        <span class="text-lg ms-n2">{{ prefix }}</span>
        {{ "" }}
        <countTo :start-val="0" :end-val="current" :duration="6000"></countTo>
        {{ "" }}
        <span class="text-lg ms-n2">/ {{ setTemp }} {{ suffix }}</span>
      </h1>

      <h6 class="mb-0 font-weight-bolder">{{ title }}</h6>
      <span
          class="mb-0 text-sm opacity-8 c-pointer"
          @click="openGaugeModal"
      >
        {{ description }}
      </span>


      <Modal
          ref="gaugeModal"
          :title='title'
          :set-temp-value="setTemp"
          :current-value="current"
          :min-value="minCount"
          :max-value="maxCount"
          :histeresis="histeresis"
          @save="handleSave"
      />
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to/src";
import {resolveComponent} from 'vue';
import TemCotel from "../../components/Icon/TemCotel.vue";
import TemBoiler from "../../components/Icon/TemBoiler.vue";
import PowerPalnik from "../../components/Icon/PowerPalnik.vue";
import Modal from "@/thsoft/Modal/Modal.vue";

export default {
  name: "CounterCardKotel",
  components: {
    Modal,
    countTo,
    TemCotel,
    TemBoiler,
    PowerPalnik,
  },
  props: {
    current: {
      type: [Number],
      required: true,
    },
    minCount: {
      type: [Number],
      required: true,
    },
    maxCount: {
      type: [Number],
      required: true,
    },
    setTemp: {
      type: [Number],
      required: true,
    },
    histeresis: {
      type: [Number],
      required: true,
    },
    icon: {
      type: String,
      default: null
    },
    infoBox: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "info",
    },
  },
  emits: ['save'],
  computed: {
    resolvedIcon() { 
      return this.icon ? resolveComponent(this.icon) : null;
    }
  },
  methods: {
    openGaugeModal() {
      this.$refs.gaugeModal.open();
    },
    handleSave(updatedValues) {
      console.log('CounterCardKotel received values:', updatedValues); 
      this.$emit("save", updatedValues);
    },
  }
};
</script>
