import itemsService from "../services/items.service";
import axios from 'axios';

const initialState = {
    items: null,
    itemsByUser: null,
    hasItems: false,
    currentParams: null,
    currentUserId: null
};

export const items = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUserByItemId(_, itemId) {
            return await itemsService.getUserByItemId(itemId);
        },
        async getItemsByUserId({ commit }, userId) {
            try {
                console.log('Getting items for user:', userId);
                const response = await itemsService.getItemsByUserId(userId);
                console.log('Response from service:', response);
                commit("SET_ITEMS_BY_USER", response);
                commit("SET_CURRENT_USER_ID", userId);
                commit("updateHasItemsStatus", Array.isArray(response.data) && response.data.length > 0, { root: true });
                commit("UPDATE_HAS_ITEMS_STATUS");
            } catch (error) {
                console.error('Error in getItemsByUserId:', error);
                commit("CLEAR_ITEMS");
                throw error;
            }
        },
        async getItems({ commit }, params) {
            const response = await itemsService.getItems(params);
            commit("SET_ITEMS", response);
            commit("SET_CURRENT_PARAMS", params);
            commit("updateHasItemsStatus", Array.isArray(response.data) && response.data.length > 0, { root: true }); // Оновлюємо глобальний статус
            commit("UPDATE_HAS_ITEMS_STATUS");
        },

        async getItem(_, id) {
            const response = await itemsService.getItem(id);
            return response;
        },

        async deleteItem({ dispatch, state, commit }, id) {
            try {
                console.log('Deleting item:', id);
                await itemsService.deleteItem(id);
                commit("CLEAR_ITEMS");
                if (state.currentUserId) {
                    await dispatch('getItemsByUserId', state.currentUserId);
                }
            } catch (error) {
                console.error('Error in deleteItem:', error);
                throw error;
            }
        },

        async addItem(_, item) {
            return await itemsService.addItem(item);
        },

        async editItem(_, item) {
            return await itemsService.editItem(item);
        },
        async uploadPic(_, object) {
            return await itemsService.uploadItemPic(object);
        },
        async startVerification(_, { serial_number }) {
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/items/verify`, {
                    serial_number: serial_number
                });
                return response.data;
            } catch (error) {
                console.error("Error starting verification:", error);
                throw error;
            }
        },
        async verifyToken(_, { serial_number, token }) {
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/items/verify-token`, {
                    serial_number: serial_number,
                    token: token
                });
                return response.data;
            } catch (error) {
                console.error("Error verifying token:", error);
                throw error;
            }
        }
    },

    mutations: {
        SET_ITEMS(state, response) {
            console.log('SET_ITEMS mutation called with:', response);
            state.items = response;
            state.hasItems = response && response.data && response.data.length > 0;
            console.log('State after SET_ITEMS:', state.items);
        },
        SET_ITEMS_BY_USER(state, response) {
            console.log('SET_ITEMS_BY_USER mutation called with:', response);

            // Process included data if present
            if (response.included) {
                const categories = response.included.filter(item => item.type === 'categories');
                const categoriesMap = new Map(categories.map(cat => [cat.id, cat]));

                // Add category data to each item
                if (Array.isArray(response.data)) {
                    response.data = response.data.map(item => ({
                        ...item,
                        category: item.category ? categoriesMap.get(item.category.id) : null
                    }));
                }
            }

            state.itemsByUser = response;
            state.hasItems = response && response.data && response.data.length > 0;
            console.log('State after SET_ITEMS_BY_USER:', state.itemsByUser);
        },
        CLEAR_ITEMS(state) {
            console.log('Clearing items state');
            state.items = null;
            state.itemsByUser = null;
            state.hasItems = false;
        },
        UPDATE_HAS_ITEMS_STATUS(state) {
            state.hasItems = state.items && state.items.data && state.items.data.length > 0;
        },
        SET_CURRENT_PARAMS(state, params) {
            state.currentParams = params;
        },
        SET_CURRENT_USER_ID(state, userId) {
            state.currentUserId = userId;
        }
    },

    getters: {
        items(state) {
            return state.items;
        },
        itemsByUser(state) {
            return state.itemsByUser;
        },
        hasItems(state) {
            return state.hasItems;
        }
    },
};