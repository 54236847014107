import store from "../store";

export default function admin({ router }) {
    const profile = store.getters["profile/profile"];
    console.log('Profile:', profile);

    let userRole = 'user';
    if (profile && profile.roles && profile.roles[0]) {
        userRole = profile.roles[0].name;
    }
    console.log('User role:', userRole);

    if (userRole !== "admin") {
        console.log('Access denied - not admin');
        return router.push({ name: "Default" });
    }
    console.log('Access granted - admin');
}