<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Основна інформація</h5>
              </div>
            </div>
          </div>

          <div class="card-body">
            <label class="form-label mt-2 row">ФІО</label>

            <soft-model-input
                id="name"
                v-model="user.name"
                type="text"
                placeholder="Your Name"
            />
            <validation-error :errors="apiValidationErrors.name"/>

              <label class="form-label mt-4">Телефон для зв'язку (не використовується для розсилки)</label>
              <soft-input
                  id="phoneNumber"
                  type="phone"
                  placeholder="+38 099 111 22 33"
              />

            <label class="form-label row mt-4">Email</label>
            <input
            id="email"
            type="text"
            class="form-control"
            name="email"
            :value="user.email"
            disabled=""
            />

            <label class="form-label row mt-4">Роль користувача</label>
            <select
                id="choices-roles"
                v-model="getRole"
                class="form-control"
                name="choices-roles"
            ></select>
            <validation-error :errors="apiValidationErrors.roles"/>


            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="updateBasicInfo"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити дані</span></soft-button>

          </div>

        </div>

        <div id="notifications" class="card mt-4">
          <div class="card-header">
            <h5>Сповіщення</h5>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                <tr>
                  <th class="ps-1" colspan="4">
                    <p class="mb-0">Подія</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">Email</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">SMS</p>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <span class="text-dark d-block text-sm">Подія1</span>
                      <span class="text-xs font-weight-normal"
                      >text1</span
                      >
                    </div>
                  </td>
                  <td>
                    <div class="ms-auto">
                      <soft-switch
                          id="mentionsEmail"
                          name="mentionsEmail"
                          checked
                      />
                    </div>
                  </td>
                  <td>
                    В розробці
                  </td>
                </tr>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <p class="text-sm mb-0">Log in from a new device</p>
                    </div>
                  </td>
                  <td>
                    <div class="ms-auto">
                      <soft-switch id="deviceEmail" name="deviceEmail" checked />
                    </div>
                  </td>

                  <td>
                    В розробці
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити пароль</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label class="form-label mt-2 row">Новий пароль</label>
            <soft-model-input
                id="new_password"
                v-model="passwordData.new_password"
                type="password"
                placeholder="Новий пароль"
            />
            <validation-error :errors="apiValidationErrors.new_password"/>

            <label class="form-label row mt-4">Підтвердження нового паролю</label>
            <soft-model-input
                id="new_password_confirmation"
                v-model="passwordData.new_password_confirmation"
                type="password"
                placeholder="Підтвердження нового паролю"
            />
            <validation-error :errors="apiValidationErrors.new_password_confirmation"/>

            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="updatePassword"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити пароль</span></soft-button>
          </div>

        </div>

        <div class="card mt-4">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Змінити email</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label class="form-label row mt-4">Email</label>
            <soft-model-input
                id="new_email"
                v-model="emailData.new_email"
                type="text"
                placeholder="Email"
            />
            <validation-error :errors="apiValidationErrors.new_email"/>

            <soft-button
                color="dark"
                variant="gradient"
                class="float-end mt-4 mb-0"
                size="sm"
                :is-disabled="loading ? true : false"
                @click="updateEmail"
            ><span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else>Оновити email</span></soft-button>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <div class="card mt-4">
          <div class="card-header">
            <h5>Видалення профілю</h5>
            <p class="text-sm mb-0">
              Після видалення облікового запису поновити не можливо.
            </p>
          </div>
          <div class="card-body d-sm-flex pt-0 justify-content-between">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div>
                <soft-switch id="deleteAccount" name="deleteAccount" />
              </div>
              <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
          >Підтвержую</span
          >
                <span class="text-xs d-block">Я хочу видалити свій акаунт.</span>
              </div>
            </div>

            <soft-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                type="button"
                name="button"
            >Видалити</soft-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftModelInput from "/src/components/SoftModelInput.vue";
import SoftButton from "/src/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import Choices from "choices.js";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";

export default {
  name: "EditUser",
  components: {
    SoftSwitch,
    SoftInput,
    SoftModelInput,
    SoftButton,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      file: null,
      user: {},
      loading: false,
      getRole: null,
      options: [],
      passwordData: {
        new_password: '',
        new_password_confirmation: ''
      },
      emailData: {
        new_email: ''
      }
    };
  },
  computed: {
    roles() {
      const roles = this.$store.getters["roles/roles"]?.data;
      var response = [];
      if (roles) {
        roles.forEach((element) => {
          response.push({
            value: element.name,
            label: element.name,
            id: element.id,
          });
        });
      }
      return response;
    },
    preview() {
      if (this.file) return URL.createObjectURL(this.file);
      else if (this.user.profile_image) return this.user.profile_image;
      else return require("/src/assets/img/placeholder.jpg");
    },
  },
  async mounted() {
    await this.$store.dispatch("roles/getRoles");
    this.user = await this.$store.dispatch("users/getUser", this.$route.params.id);
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser?.roles?.[0]?.name) {
          this.getRole = newUser.roles[0].name;
          this.initializeChoices();
        }
      }
    }
  },
  methods: {
    initializeChoices() {
      if (document.getElementById("choices-roles")) {
        var element = document.getElementById("choices-roles");
        new Choices(element, {
          searchEnabled: false,
          choices: [
            {
              value: "",
              label: "",
              selected: true,
              disabled: true,
            },
          ].concat(this.roles),
        });
      }
    },
    async updateBasicInfo() {
      this.resetApiValidation();
      this.loading = true;

      const id = this.roles.filter((obj) => {
        return obj.value === this.getRole;
      })[0]?.id;

      if (id) {
        this.user.roles = [
          {
            type: "roles",
            id: id,
          },
        ];
      }

      try {
        await this.$store.dispatch("users/editUser", {
          id: this.user.id,
          name: this.user.name,
          roles: this.user.roles
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Основну інформацію оновлено успішно!",
        });
        this.$router.push("/users/list");
      } catch (error) {
        if (error.response.data.errors) this.setApiValidation(error.response.data.errors);
        else
          showSwal.methods.showSwal({
            type: "error",
            message: "Помилка при оновленні даних!",
            width: 350,
          });
        this.loading = false;
      }
    },

    async updatePassword() {
      this.resetApiValidation();
      this.loading = true;

      try {
        await this.$store.dispatch("users/editUser", {
          id: this.user.id,
          password: this.passwordData.new_password,
          password_confirmation: this.passwordData.new_password_confirmation
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Пароль оновлено успішно!",
        });
        this.$router.push("/users/list");
      } catch (error) {
        if (error.response.data.errors) this.setApiValidation(error.response.data.errors);
        else
          showSwal.methods.showSwal({
            type: "error",
            message: "Помилка при оновленні паролю!",
            width: 350,
          });
        this.loading = false;
      }
    },

    async updateEmail() {
      this.resetApiValidation();
      this.loading = true;

      try {
        await this.$store.dispatch("users/editUser", {
          id: this.user.id,
          email: this.emailData.new_email
        });
        showSwal.methods.showSwal({
          type: "success",
          message: "Email оновлено успішно!",
        });
        this.$router.push("/users/list");
      } catch (error) {
        if (error.response.data.errors) this.setApiValidation(error.response.data.errors);
        else
          showSwal.methods.showSwal({
            type: "error",
            message: "Помилка при оновленні email!",
            width: 350,
          });
        this.loading = false;
      }
    },

    addedImage(files) {
      this.file = files[0];
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}

.choices {
  margin-bottom: 0px !important;
}
</style>
