<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">

      </div>
    </div>
    <div class="text-center card-body pt-5 ">


      <component :is="resolvedIcon" v-if="resolvedIcon"></component>

      <h1 class="text-gradient" :class="`text-${color}`">
        <span class="ms-n2">{{ resolvedMode }}</span>
      </h1>

      <h6 class="mb-0 font-weight-bolder">{{ title }}</h6>
      <span
          class="mb-0 text-sm opacity-8 c-pointer"
          @click="openGaugeModal"
      >
        {{ description }}
      </span>


      <ModalMode
          ref="gaugeModal"
          :mode-value="selectedMode"
          @save="handleSave"
      />
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to/src";
import { resolveComponent } from 'vue';
import ModalMode from "@/thsoft/Modal/ModalMode.vue";
import PumpMode from "../../components/Icon/PumpMode.vue";

export default {
  name: "SettingCounterCard",
  components: {
    ModalMode,
    PumpMode,
    countTo,
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    prefix: {
      type: String,
      default: "",
    },
    setMode: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "info",
    },
  },
  emits: ['save'],
  data() {
    return {
      selectedMode: this.setMode,
    };
  },
  computed: {
    resolvedIcon() {
      // Используем resolveComponent для нахождения компонента по названию
      return this.icon ? resolveComponent(this.icon) : null;
    },
    resolvedMode() {
      // Логика для отображения суффикса
      switch (this.selectedMode) {
        case 1:
          return "Б+К";
        case 2:
          return "Б";
        case 3:
          return "К";
        case 4:
          return "К+Б";
        default:
          return "Не обрано";  // Для значения 0 или пустого
      }
    }
  },
  watch: {
    setMode: {
      immediate: true,
      handler(newVal) {
        this.selectedMode = newVal;
      }
    }
  },
  methods: {
    openGaugeModal() {
      this.$refs.gaugeModal.open();
    },
    handleSave(updatedValues) {
      this.selectedMode = updatedValues.mode; // Обновляем локальное значение
      this.$emit("save", updatedValues); // Передаем родительскому компоненту
    },
  }
};
</script>
