<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading" class="d-flex justify-content-center my-5">
      <img src="@/assets/img/loading.gif" width="100" height="100" alt="loading"/>
    </div>
    <div v-else-if="!hasItems" class="d-grid justify-content-center my-5">
      <p>Пристроїв не знайдено</p>
      <div class="mt-4">
        <router-link to="/items/new" class="btn d-grid btn-add-device-big bg-gradient-success justify-items-center align-items-center p-3 btn-sm text-center">
          <i class="fa fa-solid fa-plus mb-2"></i> Додати пристрій
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="mt-4 row d-flex justify-center row">
        <div class="col-md-4 col-12 text-center text-md-start order-3 order-md-1">
          <p>Режим роботи: <span class="text-gradient text-info text-uppercase">{{selectedDeviceData.work_mode}}</span></p>
        </div>
        <div class="col-md-4 col-12 text-center d-inline-flex order-2 order-md-2">
          <div class="form-check form-switch ps-0 d-inline-flex">
            <input
                id="status"
                class="mt-1 form-check-input"
                type="checkbox"
                :checked="isEnabled"
                :disabled="isArchived"
                @change="toggleStatus"
            />
            <span>{{ statusLabel }}</span>
          </div>
        </div>
        <div class="col-md-4 col-12 text-center text-md-end pc-right align-items-center align-items-md-end order-1 order-md-3">
          <div class="d-xl-block" style="max-width: 300px">
            <select id="choices-item-edit" v-model="selectedDevice" class="form-control" name="choices-item" @change="onDeviceChange">
              <option v-for="item in itemsList" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <hr class="my-2 horizontal dark"/>

      <div class="mt-6 grid grid-colum-4">
        <div class="col-md-12">
          <counter-card-kotel
              :current="heaterTemp"
              :set-temp="heaterTempSet"
              :min-count="0"
              :max-count="80"
              :histeresis="heaterHysteresis"
              :info-box="'Інтенсивність набору - ' + 0.5 + '<br>Гістерезіс - ' + heaterHysteresis"
              icon="TemCotel"
              suffix="°C"
              title="Температура котла"
              description="Змінити"
              popup="Інтенсивність набору -0.5"
              @save="updateDeviceData('kotla', $event)"
          />
        </div>
        <div class="col-md-12 ">
          <counter-card-boiler
              :current="boilerTemp"
              :set-temp="boilerTempSet"
              :min-count="0"
              :max-count="80"
              :histeresis="boilerHysteresis"
              :info-box="'Інтенсивність набору - ' + 0.5 + '<br>Гістерезіс - ' + boilerHysteresis"
              icon="TemBoiler"
              suffix="°C"
              title="Температура бойлера"
              description="Змінити"
              @save="updateDeviceData('boilera', $event)"
          />
        </div>

        <div class="col-md-12">
          <setting-counter-card
              :set-mode="mode"
              icon="PumpMode"
              title="Режим насосів"
              description="Змінити"
              @save="updateDeviceData('mode', $event)"
          />
        </div>
        <div class="col-md-12 ">
          <temp-counter-card
              :current="gasTemp"
              :set-min-temp="gasTempMin"
              :set-max-temp="gasTempMax"
              :min-count="80"
              :max-count="500"
              :info-box="'Діапазон дим.газів  ' + gasTempMin + ' | ' + gasTempMax + '°C'"
              icon="SmokeGases"
              suffix="°C"
              title="Димові гази"
              description="Змінити"
              @save="updateDeviceData('gas', $event)"
          />
        </div>
        <div class="col-md-12 ">
          <power-counter-card
              :current="burnerPower"
              :set-temp="burnerPowerSet"
              :min-count="5"
              :max-count="1000"
              :burner-mod="burnerMod"
              :info-box="`<div style='display: flex; justify-content: space-between; width: 100%;'>
                <span>Потужність пальника:</span> <span>${burnerPowerSet} | ${burnerPower} °C</span>
            </div>
            <div style='display: flex; justify-content: space-between; width: 100%;'>
                <span>Модуляція</span> <span>${burnerMod ? 'увімк' : 'вимк'}</span>
            </div>
            <div style='display: flex; justify-content: space-between; width: 100%;'>
                <span>Поточне спожив.п</span> <span>2.8 кг/год</span>
            </div>`"
              icon="PowerPalnik"
              suffix="kWt"
              title="Потужність пальника"
              description="Змінити"
              @save="updateDeviceData('power', $event)"
          />
        </div>

      </div>
      <div class="row">
        <SegmentedLoader :progress="selectedDeviceData.flame_brightness"/>
      </div>

      <hr class="my-4 horizontal dark"/>
      <div class="mt-4 row">
        <div class="col-lg-6 ms-auto">
          <reports-doughnut-chart/>
        </div>
        <div class="mt-4 col-lg-6 mt-lg-0">
          <div class="row">
            <div class="col-12">
              <thin-bar-chart
                  title="Споживання палива"
                  :chart="{
                    labels: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'],
                    datasets: {
                      label: 'kg',
                      data: [150, 230, 380, 220, 420, 200, 70, 500]
                    }
                  }"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="my-5 horizontal dark"/>
    </div>
  </div>
</template>

<script>
import ReportsDoughnutChart from "@/thsoft/Charts/ReportsDoughnutChart.vue";
import ThinBarChart from "@/thsoft/Charts/ThinBarChart.vue";
import PowerCounterCard from "@/thsoft/Cards/PowerCounterCard";

import SettingCounterCard from "@/thsoft/Cards/SettingCounterCard.vue";
import SegmentedLoader from "@/components/SegmentedLoader.vue";
import TempCounterCard from "@/thsoft/Cards/TempCounterCard.vue";
import Choices from "choices.js";
import ItemService from "@/services/items.service.js";
import CounterCardKotel from "../../thsoft/Cards/CounterCardKotel.vue";
import CounterCardBoiler from "../../thsoft/Cards/CounterCardBoiler.vue";


export default {
  name: "Default",
  components: {
    CounterCardBoiler,
    CounterCardKotel,
    TempCounterCard,
    SegmentedLoader,
    SettingCounterCard,
    PowerCounterCard,
    ReportsDoughnutChart,
    ThinBarChart,
  },
  emits: ['save'],
  data() {
    return {
      selectedDeviceData: {},
      selectedDevice: null,
      devices: [],
      showMenu: false,
      isLoading: true,
      isPowerOn: false,
      updateInterval: null,
    };
  },
  computed: {
    isEnabled() {
      return this.selectedDeviceData.status === "on";
    },
    isArchived() {
      return this.selectedDeviceData.status === "archive";
    },
    statusLabel() {
      switch (this.selectedDeviceData.status) {
        case "on":
          return "Увімкнено";
        case "off":
          return "Вимкнено";
        case "archive":
          return "Заблоковано";
        default:
          return "Невідомий статус";
      }
    },
    currentUser() {
      const user = this.$store.getters["profile/profile"]?.id;
      return user;
    },
    itemsList() {
      const items = this.$store.getters["items/items"]?.data || [];
      return items.filter(item => item.status !== "pending");
    },
    hasItems() {
      const items = this.$store.getters["items/items"]?.data;
      return items && items.length > 0 && items.some(item => item.status !== "pending");
    },
    heaterTemp() {
      return this.selectedDeviceData.heater_temp;
    },
    heaterTempSet() {
      return this.selectedDeviceData.heater_temp_set;
    },
    heaterHysteresis() {
      return this.selectedDeviceData.heater_hysteresis;
    },
    boilerTemp() {
      return this.selectedDeviceData.boiler_temp;
    },
    boilerTempSet() {
      return this.selectedDeviceData.boiler_temp_set;
    },
    boilerHysteresis() {
      return this.selectedDeviceData.boiler_hysteresis;
    },
    gasTemp() {
      return this.selectedDeviceData.gas_temp;
    },
    gasTempMin() {
      return this.selectedDeviceData.gas_temp_min;
    },
    gasTempMax() {
      return this.selectedDeviceData.gas_temp_max;
    },
    burnerPower() {
      return this.selectedDeviceData.burner_power;
    },
    burnerPowerSet() {
      return this.selectedDeviceData.burner_power_set;
    },
    burnerMod() {
      return this.selectedDeviceData.burner_mod;
    },
    mode() {
      return this.selectedDeviceData.pump_mode;
    }
  },
  watch: {
    itemsList: {
      immediate: true,
      handler(newItems) {
        console.log('itemsList changed:', newItems);
        if (newItems && newItems.length > 0) {
          const devicesOnHomepage = newItems.filter(device => device.is_on_homepage === true);
          console.log('Devices on homepage:', devicesOnHomepage);
          
          if (devicesOnHomepage.length > 0) {
            // Завжди встановлюємо перший пристрій як вибраний
            this.selectedDevice = devicesOnHomepage[0].id;
            this.selectedDeviceData = devicesOnHomepage[0];
          } else if (newItems.length > 0) {
            // Якщо немає пристроїв на головній сторінці, але є активні пристрої, вибираємо перший
            this.selectedDevice = newItems[0].id;
            this.selectedDeviceData = newItems[0];
          } else {
            // Якщо немає пристроїв взагалі, очищаємо дані
            this.selectedDevice = null;
            this.selectedDeviceData = {};
          }
        } else {
          // Якщо немає пристроїв взагалі, очищаємо дані
          this.selectedDevice = null;
          this.selectedDeviceData = {};
        }
      }
    },
    // Додаємо спостереження за змінами в store
    '$store.state.items.items': {
      handler(newItems) {
        console.log('Store items changed:', newItems);
        if (newItems && newItems.data) {
          const activeDevices = newItems.data.filter(item => item.status !== "pending");
          const devicesOnHomepage = activeDevices.filter(item => item.is_on_homepage === true);
          
          if (devicesOnHomepage.length > 0) {
            this.selectedDevice = devicesOnHomepage[0].id;
            this.selectedDeviceData = devicesOnHomepage[0];
          } else if (activeDevices.length > 0) {
            this.selectedDevice = activeDevices[0].id;
            this.selectedDeviceData = activeDevices[0];
          } else {
            this.selectedDevice = null;
            this.selectedDeviceData = {};
          }
        }
      },
      deep: true
    },
    selectedDevice: {
      immediate: true,
      handler(newDeviceId) {
        console.log('selectedDevice changed:', newDeviceId);
        if (newDeviceId) {
          this.loadDeviceData(newDeviceId);
        }
      }
    }
  },
  mounted() {
    this.loadItems();
    this.initializeChoices();
    // Запускаємо автоматичне оновлення даних
    this.startAutoUpdate();
  },
  beforeUnmount() {
    // Зупиняємо інтервал при видаленні компонента
    this.stopAutoUpdate();
  },
  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        // Очищаємо store перед завантаженням нових даних
        this.$store.commit('items/CLEAR_ITEMS');
        await this.$store.dispatch("items/getItems", {
          user_id: this.currentUser
        });
        
        // Get devices from store
        const items = this.$store.getters["items/items"]?.data || [];
        console.log('Loaded items:', items);
        
        // Filter out pending devices and only get active ones
        const activeDevices = items.filter(item => item.status !== "pending");
        console.log('Active devices:', activeDevices);
        
        // Якщо немає активних пристроїв, очищаємо вибір
        if (activeDevices.length === 0) {
          console.log('No active devices found, clearing selection');
          this.selectedDevice = null;
          this.selectedDeviceData = {};
          this.isLoading = false;
          return;
        }
        
        const devicesOnHomepage = activeDevices.filter(item => item.is_on_homepage === true);
        console.log('Devices on homepage:', devicesOnHomepage);
        
        if (devicesOnHomepage.length > 0) {
          // Якщо є пристрої на головній сторінці, вибираємо перший
          this.selectedDevice = devicesOnHomepage[0].id;
          this.selectedDeviceData = devicesOnHomepage[0];
        } else if (activeDevices.length > 0) {
          // Якщо немає пристроїв на головній сторінці, але є активні пристрої, вибираємо перший
          this.selectedDevice = activeDevices[0].id;
          this.selectedDeviceData = activeDevices[0];
        }
        
        this.isLoading = false;
      } catch (error) {
        console.error("Error loading items:", error);
        this.isLoading = false;
        // Очищаємо дані при помилці
        this.selectedDevice = null;
        this.selectedDeviceData = {};
      }
    },
    startAutoUpdate() {
      // Оновлюємо дані кожні 30 секунд
      this.updateInterval = setInterval(() => {
        if (this.selectedDevice) {
          this.loadDeviceData(this.selectedDevice);
        }
        // Також оновлюємо список пристроїв
        this.loadItems();
      }, 30000);
    },
    stopAutoUpdate() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },
    async loadDeviceData(deviceId) {
      if (!deviceId) {
        console.warn('Attempted to load device data with undefined deviceId');
        return;
      }

      try {
        console.log('Loading device data for ID:', deviceId);
        const response = await ItemService.getItem(deviceId);
        console.log('Received device data:', response);
        
        // Якщо пристрій не знайдено, очищаємо все
        if (!response || !response.data) {
          console.log('Device not found in database, clearing everything');
          this.$store.commit('items/CLEAR_ITEMS');
          this.selectedDevice = null;
          this.selectedDeviceData = {};
          return;
        }
        
        // Skip loading data for pending devices
        if (response.data.status === 'pending') {
          console.warn('Skipping data load for pending device:', deviceId);
          return;
        }

        // Clean up the data before storing
        const cleanData = { ...response.data };
        delete cleanData.created_at;
        delete cleanData.updated_at;
        delete cleanData.links;
        
        // Update data in store
        const currentItems = this.$store.getters["items/items"];
        if (currentItems && currentItems.data) {
          const updatedItems = {
            ...currentItems,
            data: currentItems.data.map(item => 
              item.id === deviceId ? cleanData : item
            )
          };
          this.$store.commit('items/SET_ITEMS', updatedItems);
        }
        
        // Update local data
        this.selectedDeviceData = cleanData;
      } catch (error) {
        console.error('Error loading device data:', error);
        // Очищаємо дані при помилці
        this.$store.commit('items/CLEAR_ITEMS');
        this.selectedDevice = null;
        this.selectedDeviceData = {};
      }
    },
    async onDeviceChange() {
      if (!this.selectedDevice) return;
      
      try {
        await this.loadDeviceData(this.selectedDevice);
        // Оновлюємо список пристроїв після зміни
        await this.loadItems();
      } catch (error) {
        console.error("Error loading device data:", error);
        // Очищаємо дані при помилці
        this.$store.commit('items/CLEAR_ITEMS');
        this.selectedDevice = null;
        this.selectedDeviceData = {};
      }
    },
    async updateDeviceData(type, updatedValues) {
      try {
        // Create a clean object with only the fields we need
        const updateData = {
          id: this.selectedDevice,
          type: "items"
        };
        
        // Add only the fields that need to be updated
        switch (type) {
          case 'kotla':
            updateData.heater_temp_set = parseInt(updatedValues.setTemp);
            updateData.heater_hysteresis = parseFloat(updatedValues.histeresis);
            break;
          case 'boilera':
            updateData.boiler_temp_set = parseInt(updatedValues.setTemp);
            updateData.boiler_hysteresis = parseFloat(updatedValues.histeresis);
            break;
          case 'gas':
            updateData.gas_temp_min = parseInt(updatedValues.setMinTemp);
            updateData.gas_temp_max = parseInt(updatedValues.setMaxTemp);
            break;
          case 'power':
            updateData.burner_power_set = parseInt(updatedValues.setTemp);
            updateData.burner_mod = updatedValues.burner_mod === true;
            break;
          case 'mode':
            updateData.pump_mode = parseInt(updatedValues.mode);
            break;
        }

        // Remove any undefined or null values
        Object.keys(updateData).forEach(key => {
          if (updateData[key] === undefined || 
              updateData[key] === null || 
              key === 'created_at' || 
              key === 'updated_at') {
            delete updateData[key];
          }
        });

        console.log('Sending update to server:', updateData);

        // Відправляємо дані на сервер
        const response = await ItemService.editItem(updateData);
        console.log('Server response:', response);

        // Оновлюємо дані з сервера
        await this.loadDeviceData(this.selectedDevice);

        // Оновлюємо локальні дані
        if (type === 'mode') {
          this.selectedDeviceData.pump_mode = parseInt(updatedValues.mode);
        }
      } catch (error) {
        console.error('Error updating device data:', error);
      }
    },
    async toggleStatus() {
      if (this.isArchived) {
        return;
      }
      try {
        const newStatus = this.isEnabled ? 'off' : 'on';
        await ItemService.editItem({
          id: this.selectedDevice,
          status: newStatus
        });
        this.selectedDeviceData.status = newStatus;
      } catch (error) {
        console.error('Error toggling status:', error);
      }
    },
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
        });
      }
    },
    initializeChoices() {
      // Initialize Choices for the select element
      const selectElement = document.getElementById('choices-item-edit');
      if (selectElement) {
        new Choices(selectElement, {
          searchEnabled: false,
        });
      }
    },
  },
};
</script>

<style>
.btn-add-device-big {
  justify-items: center;
}
.btn-add-device-big i.fa.fa-solid.fa-plus {
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #fff;
  padding: 18px;
  width: 48px;
}
</style>