import axios from "axios";
import authHeader from "./auth-header";
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/';
const dataFormatter = new Jsona();

class ProfileService {
    async getProfile() {
        const response = await axios.get(API_URL + "me", {
            headers: authHeader(),
            params: { include: "roles" }
        });
        return dataFormatter.deserialize(response.data);
    }

    async uploadPic(object) {
        let formData = new FormData();
        formData.append("attachment", object.pic);
        const response = await axios.post(
            API_URL + "uploads/users/" + object.id + "/profile-image",
            formData, {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
        return response.data.url;
    }

    async updateProfile(profile) {
        profile.type = "profile";
        delete profile.links;
        const response = await axios.patch(
            API_URL + "me",
            dataFormatter.serialize({ stuff: profile }), {
                headers: authHeader(),
                params: { include: "roles" },
            }
        );
        return dataFormatter.deserialize(response.data);
    }

    async updatePassword(data) {
        const response = await axios.patch(
            API_URL + "me",
            dataFormatter.serialize({
                stuff: {
                    type: "profile",
                    current_password: data.current_password,
                    password: data.new_password,
                    password_confirmation: data.password_confirmation
                }
            }), {
                headers: authHeader()
            }
        );
        return dataFormatter.deserialize(response.data);
    }

    async updateEmail(data) {
        // Спочатку перевіряємо поточний пароль
        try {
            await axios.post(API_URL + "auth/check-password", {
                password: data.current_password
            }, {
                headers: authHeader()
            });

            // Якщо пароль вірний, оновлюємо email
            const response = await axios.patch(
                API_URL + "me",
                dataFormatter.serialize({
                    stuff: {
                        type: "profile",
                        email: data.email
                    }
                }), {
                    headers: authHeader()
                }
            );
            return dataFormatter.deserialize(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                throw new Error("Невірний поточний пароль");
            }
            throw error;
        }
    }

    async deleteProfile() {
        const response = await axios.delete(API_URL + "me", {
            headers: authHeader()
        });
        return dataFormatter.deserialize(response.data);
    }
}

export default new ProfileService();